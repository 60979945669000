<template>
  <div class="report-list-wrap">
    <template-search-read-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :reload="reload"
                          :initialSearchFilter="initialSearchFilter"
                          :selected="selected" 
                          :heightOffset="310"
                          @select="setSelected"
                          @searchFilter="getSearchFilter"
                          @showDetail="showDetail"
                          @toReport="toReport"                                                   
                          >
    </template-search-read-list>

    <template-detail :infoApi="infoApi"
                     :apiParam="apiParam"
                     @close="setSelected">
    </template-detail>
  </div>
</template>

<script>

import { SOLOMON_URL } from '@/utils/tools'
import TemplateSearchReadList from '@/components/_template/SearchReadList.vue'
import TemplateDetail from '@/components/_template/Detail'
import UiButton from '@/components/_ui/UiButton'

import { EventBus } from '@/utils/event-bus'
import { getBaAiReportList, getBaAiReportInfo } from '@/api/mine'
import { STORAGE_ITEM } from '@/utils/constants'

export default {
  name: 'BeusCreditList',
  components: {
    TemplateSearchReadList,
    TemplateDetail,
    UiButton
  },
  created () {
    const localStorageData = localStorage.getItem(STORAGE_ITEM.searchData)
    if (localStorageData !== '{}' || localStorageData !== 'null') {
      const searchData = JSON.parse(localStorageData)
      if (searchData && searchData.userId) {
        this.initialSearchFilter = {}
        this.initialSearchFilter.userId = searchData.userId
        this.initialSearchFilter.userIdExact = true
      }
    }
    localStorage.setItem(STORAGE_ITEM.searchData, null)
  },
  methods: {
    reloadList () {
      this.reload++
    },
    getSearchFilter (value) {
      this.searchFilter = value
    },
    setSelected (row) {
      this.selected = row
    },  
    showDetail (data) {
      this.apiParam = { aid: data._id }
    },
    toReport (data) {
      window.open(`${SOLOMON_URL}/report/${data._id}`)
    }
  },
  data () {
    return {
      reload: 0,
      selected: null,      
      apiParam: null,

      listApi: getBaAiReportList,
      infoApi: getBaAiReportInfo,      
      buttonOption: [
        {
          action: 'toReport',
          columnText: '리포트',
          buttonText: '<i class="xi-document"></i>',
          if: ['state', '!=', 'error']
        },
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-regDate',
      searchFilter: {},
      initialSearchFilter: null
    }
  }
}
</script>

<style lang="scss" scoped>
.report-list-wrap {
}
</style>
