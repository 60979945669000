<template>
  <div class="table-list"
       :class="{ inside }">
    <div class="column-fix-checkbox-wrap" v-if="columnFixed > 0">
      <ui-checkbox :placeholder="'컬럼 고정'"
                   :value="isColFix"
                   @input="isColFix = !isColFix">
        컬럼 고정
      </ui-checkbox>
    </div>
    <ui-button v-if="canHide" @click="openTable = !openTable">
      {{openTable ? '접기' : '열기'}}
    </ui-button>
    <div class="list-table-wrap"
         :style="{height: heightOffset ? `calc(100vh - ${heightOffset}px)`: ''}"
         :ref="'tableList'"
         v-if="openTable">
      <table class="list-table" ref="currentTable">
        <thead>
          <tr>
            <th v-for="(buttonData, idx) in buttonOption"
                :key="`th${buttonData.action}`"
                class="button-th"
                :class="{'fixed': fixedLeftSizeList.length > 0}"
                :style="{
                  left: fixedLeftSizeList[idx] ? fixedLeftSizeList[idx].left + 'px' : '',
                  width: fixedLeftSizeList[idx] ? fixedLeftSizeList[idx].width + 'px' : ''
                }"
                v-html="buttonData.columnText">
            </th>
            <th v-for="([field, col], colnum) of Object.entries(columnData)"
                v-if="!col.hidden"
                :class="[columnFixed && colnum <= columnFixed ? 'fixed':'', field]"
                :style="{
                  left: fixedLeftSizeList[(colnum - hiddenColumnCount) + buttonOption.length] ? fixedLeftSizeList[(colnum - hiddenColumnCount) + buttonOption.length].left + 'px' : '',
                  width: fixedLeftSizeList[(colnum - hiddenColumnCount) + buttonOption.length] ? fixedLeftSizeList[(colnum - hiddenColumnCount) + buttonOption.length].width + 'px' : ''
                }"
                :key="field">
              <div class="col-wrap">
                <div class="col-name"
                     v-html="col.name">
                </div>
                <div v-if="col.sortable !== false"
                     class="col-sort">
                  <button v-if="sortBy === '+' + field"
                          @click="changeSort('+' + field)">
                    <i class="xi-caret-up"></i>
                  </button>
                  <button v-else
                          @click="changeSort('+' + field)">
                    <i class="xi-angle-up"></i>
                  </button>

                  <button v-if="sortBy === '-' + field"
                          @click="changeSort('-' + field)">
                    <i class="xi-caret-down"></i>
                  </button>
                  <button v-else
                          @click="changeSort('-' + field)">
                    <i class="xi-angle-down"></i>
                  </button>
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <tbody v-if="listData.length > 0">
          <tr class="table-row"
              v-for="(data, rownum) of listData"
              :key="`tr${rownum}`">
            <td v-for="(buttonData, btnnum) of buttonOption"
                :key="`tr${rownum}btn${btnnum}`"
                :class="{ 'row-hover': hover.row === rownum, 'col-hover': hover.col === `btn${btnnum}`, 'fixed': fixedLeftSizeList.length > 0}"
                :style="{
                  left: fixedLeftSizeList[btnnum] ? fixedLeftSizeList[btnnum].left + 'px' : '',
                  width: fixedLeftSizeList[btnnum] ? fixedLeftSizeList[btnnum].width + 'px' : ''
                }"
                @mouseenter="onHover(rownum, `btn${btnnum}`)"
                class="button-cell">
                <ui-button :color="buttonData.action !== 'showDetail' ? 'yellow' : selected === rownum ? 'red' : 'gray' "
                         :disabled="buttonData.if && isIf(data[buttonData.if[0]], buttonData.if[1], buttonData.if[2])"
                         @click="onClickButton(buttonData, data, rownum)">
                <span v-html="buttonData.buttonText"></span>
              </ui-button>
            </td>
            <td v-for="([field, col], colnum) of Object.entries(columnData)"
                v-if="!col.hidden"
                class="table-row-data-wrap"
                :class="{ 'row-hover': hover.row === rownum, 'col-hover': hover.col === colnum, 'fixed' : colnum <= columnFixed && columnFixed}"
                :style="{
                  left: fixedLeftSizeList[(colnum - hiddenColumnCount) + buttonOption.length] ? fixedLeftSizeList[(colnum - hiddenColumnCount) + buttonOption.length].left + 'px' : '',
                  width: fixedLeftSizeList[(colnum - hiddenColumnCount) + buttonOption.length] ? fixedLeftSizeList[(colnum - hiddenColumnCount) + buttonOption.length].width + 'px' : ''
                }"
                :key="`tr${rownum}td${colnum}`"
                @mouseenter="onHover(rownum, colnum)"
                >
              <div v-if="col.type === 'button'"
                   class="in-table-button">
                <div v-if="col.value=='confirm'">
                  <ui-button :color="col.color ? col.color : 'yellow'"
                              :disabled="data.confirm" @click="$emit('button', col.value, data)">
                    {{ data.confirm ? col.disableLabel : col.label }}
                  </ui-button>
                </div>
                <div v-else>
                  <ui-button :color="col.color ? col.color : 'yellow'"
                              @click="$emit('button', col.value, data)">
                    {{ col.label }}
                  </ui-button>
                </div>

              </div>
              <ui-data v-else-if="!col.editNow"
                      :fieldName="col.field"
                      :type="col.type"
                      :value="data[col.field || field]"
                      :align="col.align"
                      :option="col.option"
                      :model="data"
                      :listData="col.options" />
              <div v-else
                   class="in-table-input">
                <ui-data-input :type="col.type"
                               :label="null"
                               :options="col.options"
                               :update="col.update"
                               :required="col.required"
                               :model="data"
                               :field="col.field || field"
                               :value="data[col.field || field] || col.default"
                               :error="error && error[rownum] ? error[rownum][col.field || field] : null"
                               @input="$emit('set', rownum, (col.field || field), $event)" />
              </div>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td class="no-data"
                :colspan="Object.keys(columnData).length + buttonOption.length">
              <i class="xi-emoticon-neutral-o"></i><br>
              데이터가<br> 없습니다
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="navigation"
         class="table-list-navigation">
      <ui-navigation :pageNum="navigation.pageNum"
                     :pageSize="navigation.pageSize"
                     :totalCount="navigation.totalCount"
                     @goToPage="goToPage"
                     @setItemsPerPage="setItemsPerPage" />
    </div>
  </div>
</template>

<script>
import { isIf, hasKey } from '@/utils/tools'

import UiNavigation from '@/components/_ui/UiNavigation'
import UiButton from '@/components/_ui/UiButton'
import UiCheckbox from '@/components/_ui/UiCheckbox'
import UiData from '@/components/_ui/UiData'
import UiDataInput from '@/components/_ui/UiDataInput'

export default {
  name: 'UiTableList',
  components: {
    UiNavigation,
    UiButton,
    UiCheckbox,
    UiData,
    UiDataInput
  },
  props: {
    navigation: {
      validator: (value) => {
        return hasKey(value, ['pageNum', 'pageSize', 'totalCount'])
      },
      default: null,
      required: false
    },
    columnData: {
      type: Object,
      required: true
    },
    listData: {
      type: Array,
      required: true
    },
    sortBy: {
      type: String,
      default: '',
      required: false
    },
    inside: {
      type: Boolean,
      default: false,
      required: false
    },
    buttonOption: {
      type: Array,
      default: () => [],
      required: false
    },
    error: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    },
    columnFixed: {
      type: Number,
      default: -1,
      required: false
    },
    canHide: {
      type: Boolean,
      default: false,
      required: false
    },
    selected: {
      type: Number,
      required: false,
    },
    heightOffset: {
      type: Number,
      required: false
    }
  },
  watch: {
    listData () {
      this.$refs.tableList.scroll(0, 0)
      this.calculateColumnFixed()
    },
    isColFix () {
      if (this.isColFix) {
        this.calculateColumnFixed()
      } else {
        this.fixedLeftSizeList = []
      }
    }
  },
  created () {
    if (this.columnFixed > -1) {
      this.isColFix = true
    }
    this.calculateHiddenColumnCount()
  },
  mounted () {
    this.calculateColumnFixed()
  },
  methods: {
    calculateHiddenColumnCount () {
      Object.entries(this.columnData).forEach(([key, value], index) => {
        if (value.hidden) {
          this.hiddenColumnCount++
        }
      })
    },
    calculateColumnFixed () {
      setTimeout(() => {
        const table = this.$refs.currentTable
        const ths = table.querySelector('thead').querySelectorAll('th')
        if (this.isColFix && this.columnFixed > 0) {
          this.fixedLeftSizeList = []
          let prevWidth = 0
          for (let i = 0, iLen = (this.columnFixed + 1) + this.buttonOption.length; i < iLen; i++) {
            const th = ths[i]
            const width = th.getBoundingClientRect().width

            if (i === 0) {
              this.fixedLeftSizeList.push({
                left: 0,
                width: width
              })
            } else {
              this.fixedLeftSizeList.push({
                left: prevWidth - 1,
                width: width
              })
            }

            prevWidth = prevWidth - 1 + width
          }
        }
      }, 100)
    },
    isIf (a, b, c) {
      return isIf(a, b, c)
    },
    changeSort (key) {
      const sort = this.sortBy === key ? '' : key
      this.$emit('changeSort', sort)
    },
    goToPage (no) {
      this.$emit('goPage', no)
    },
    onHover (row, col) {
      this.hover.row = row
      this.hover.col = col
    },
    search () {
      this.$emit('search')
    },
    setItemsPerPage (pageNum, pageSize) {
      this.navigation.pageNum = pageNum
      this.navigation.pageSize = pageSize
      this.$emit('changePageSize', pageNum, pageSize)
    },
    onClickButton (buttonOption, data, row) {
      this.hover.row = row
      this.$emit('buttonAction', buttonOption.action, data, row)
    },
  },
  data () {
    return {
      fixedLeftSizeList: [],
      hiddenColumnCount: 0,
      hover: {
        row: null,
        col: null
      },
      isColFix: false,
      openTable: !this.canHide
    }
  }
}
</script>

<style lang="scss" scoped>
.table-list {
  .column-fix-checkbox-wrap {
    width: 162px;
    height: 4px;
    margin-left: 30px;
  }
  &.inside {
    .list-table-wrap {
      height: auto;
      margin: 0;
      overflow: initial;
    }

    .list-table tbody tr td.no-data {
      height: 200px;
      padding: 0 !important;
    }
  }

  .table-list-navigation {
    margin: 15px 20px;
  }

  .list-table-wrap {
    margin: 20px 0 15px 20px;
    padding-right: 5px;
    overflow: auto;
    height: calc(100vh - 152px);
    border-bottom: 1px solid $light;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      box-shadow: inset 0 0 10px 10px $gray;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      box-shadow: inset 0 0 10px 10px $gray;
    }
  }

  .list-table {
    width: 100%;
    border-collapse: collapse;

    thead {

      tr {
        position: sticky;
        top: 0;
        z-index: 999999;

        th {
          background: $dimlight;
          border-right: 1px solid $light;
          white-space: nowrap;
          font-weight: bold;
          z-index: 9999999;

          &.button-th {
            font-size: 12px;
            color: $gray;
            font-weight: normal;
          }

          &.fixed {
            position: sticky;
            z-index: 10;
            box-shadow: inset -1px 0px $light
          }

          &:first-child {
            border-radius: 10px 0 0 10px;
          }

          &:last-child {
            border-radius: 0 10px 10px 0;
            border-right: 0;
          }

          .col-wrap {
            width: 100%;
            display: flex;
            align-items: center;

            .col-name {
              flex-grow: 1;
              padding: 10px;
            }

            .col-sort {
              flex-grow: 0;
              flex-basis: 20px;
              text-align: left;
              padding: 5px 0;

              button {
                display: block;
                font-size: 11px;
                color: $blue;

                .xi-caret-up,
                .xi-caret-down {
                  color: $red;
                }
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          height: 25.5px;
          padding: 5px 10px;
          border-left: 1px solid $dim;
          border-bottom: 1px solid $light;
          transition: background-color 0.15s ease,
                      border-radius 0.15s ease;

          &:first-child {
            border-left: none;
          }

          .ui-data {
            line-height: 1.4;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            max-width: 300px;
            text-overflow: ellipsis;
          }

          .in-table-button {
            text-align: center;
          }

          .in-table-input {
            white-space: nowrap;
            width: 100px;

            & :deep {
              .ui-input {
                display: inline-block;
                vertical-align: middle;
                padding-bottom: 0;
              }
            }
          }

          &.button-cell {
            width: 10px;
            padding: 2px 5px;
            text-align: right;
            border-left: none;
          }

          &.col-hover {
            background-color: $dim !important;
          }

          &.row-hover {
            background-color: $light !important;
          }

          &.no-data {
            height: calc(100vh - 335px);
            padding: 50px 0 !important;
            color: $gray;
            text-align: center;
            font-size: 20px;
            line-height: 1.3;

            i {
              color: $yellow;
              font-size: 100px;
            }
          }

          &.fixed {
            position: sticky;
            background-color: $white;
            z-index: 9999;
            box-shadow: inset -1px 0px $dim
          }
        }

        &:first-child {
          td {
            padding-top: 6px;

            &.button-cell {
              padding-top: 3px;
            }
          }
        }

        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
