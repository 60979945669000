<template>
  <div class="monitoring-wrap">
    <template-search-read :readApi="readApi"
                          :reload="reload"
                          @button="button"
                          @searchFilter="getSearchFilter">
    </template-search-read>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import { alertError } from '@/utils/tools'

import { getBaPVMonitoring, confirmBaPVMonitoring, saveMemoBaPVMonitoring } from '@/api/monitoring'

import TemplateSearchRead from '@/components/_template/SearchRead'

export default {
  name: 'MonitoringBaPV',
  components: {
    TemplateSearchRead
  },
  methods: {
    getSearchFilter (value) {
      this.searchFilter = value
    },
    button (value, data) {
      if (value === 'confirm') {
        if (confirm('확인 처리를 하시겠습니까?')) {
          EventBus.$emit('onLoading', true)
          confirmBaPVMonitoring({
            id: data._id
          }).catch(error => {
            alertError('확인 처리에 실패했습니다.', error)
          }).finally(() => {
            EventBus.$emit('onLoading', false)
            this.reload++
          })
        }
      } else if (value === 'save') {
        if (confirm('메모를 저장 하시겠습니까?')) {
          EventBus.$emit('onLoading', true)
          saveMemoBaPVMonitoring({
            id: data._id,
            memo: data.memo
          }).catch(error => {
            alertError('저장에 실패했습니다.', error)
          }).finally(() => {
            EventBus.$emit('onLoading', false)
            this.reload++
          })
        }
      }
    }
  },
  data () {
    return {
      reload: 0,
      readApi: getBaPVMonitoring,
      searchFilter: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.monitoring-wrap {
}
</style>
