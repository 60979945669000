<template>
  <ui-side-modal v-show="show"
                 class="template-detail"
                 :width="modalWidth"
                 @close="closeModal">
    <ui-detail v-if="dataOption && dataInHand"
               :meta="dataOption"
               :data="dataInHand"
               :error="error"
               :editMode="editMode"
               :tableSortBy="tableSortBy"
               @set="setData"
               @error="setError"
               @input="input"
               @changeTableSort="changeTableSort"
               @button="button">
      <slot></slot>
      <template v-if="canRemove">
        <ui-button class="remove-button"
                   :color="'gray'"
                   @click="remove">
          <i class="xi-trash"></i> 삭제
        </ui-button>
      </template>
      <template v-if="canEdit">
        <template v-if="editMode">
          <ui-button :color="'red'"
                     @click="save(true)">
            <i class="xi-pen"></i> 완료
          </ui-button>
          <ui-button :color="'gray'"
                     @click="save(false)">
            <i class="xi-close"></i> 취소
          </ui-button>
        </template>
        <template v-else>
          <ui-button :color="'yellow'"
                     @click="edit">
            <i class="xi-pen"></i> 수정
          </ui-button>
          <ui-button :color="'blue'"
                     @click="send">
            <i class="xi-mail"></i> 발송
          </ui-button>
        </template>
      </template>
    </ui-detail>
  </ui-side-modal>
</template>

<script>
import i18n from '@/libs/text/mailer-i18n-messages.json'
import ejs from 'ejs'
import { EventBus } from '@/utils/event-bus'
import planStore from '@/store/modules/plan'
import { getEmailTemplate, sendBaMail } from '@/api/monitoring'
import { alertError, isEndWithConsonant } from '@/utils/tools'
import UiDetail from '@/components/_ui/UiDetail'
import UiButton from '@/components/_ui/UiButton'
import UiSideModal from '@/components/_ui/UiSideModal'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export default {
  name: 'MonitoringEmailDetail',
  components: {
    UiDetail,
    UiButton,
    UiSideModal
  },
  props: {
    infoApi: {
      required: true
    },
    editApi: {
      required: false
    },
    removeApi: {
      required: false
    },
    apiParam: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    }
  },
  created () {
    // if (this.apiParam) {
    //   this.loadData(() => {
    //     this.setEjsRendering()
    //   })
    // }
  },
  computed: {
    modalWidth () {
      return 1200
    },
    canEdit () {
      if (this.editApi && this.dataOption) {
        for (const option of Object.values(this.dataOption.field)) {
          if (option.editable) {
            return true
          }
        }
      }
      return false
    },
    canRemove () {
      if (this.removeApi && this.dataOption) {
        for (const option of Object.values(this.dataOption.field)) {
          if (option.key && option.deletable) {
            return true
          }
        }
      }
      return false
    }
  },
  watch: {
    editMode (value) {
      this.setEjsRendering()
    },
    apiParam (value) {
      this.editMode = false
      if (value) {
        this.loadData(() => {
          this.setEjsRendering()
        })
      }
    }
  },
  methods: {
    loadData (callback = null) {
      this.error = {}
      this.show = false
      EventBus.$emit('onLoading', true)
      setTimeout(() => {
        this.infoApi(this.apiParam, true).then(response => {
          this.dataInHand = response.result.info || null
          this.dataOption = response.result.meta || null
          this.show = true
        }).catch(error => {
          alertError('상세 데이터를 불러오지 못 했습니다.', error)
        }).finally(() => {
          if (callback) {
            callback()
          }
          EventBus.$emit('onLoading', false)
        })
      }, 300)
    },
    send () {
      this.model.content = this.dataInHand.content
      sendBaMail(this.model)
        .then(response => {
          alert('발송이 완료되었습니다.')
          this.apiParam = null
        })
        .catch(e => {
          alert('발송 중 오류가 발생하였습니다.', e)
        })
    },
    setError (key, message) {
      this.$set(this.error, key, message)
    },
    checkRequired () {
      if (this.dataOption) {
        for (const [field, option] of Object.entries(this.dataOption.field)) {
          const key = option.field || field
          if (option.required && !this.model[key] && this.model[key] !== 0) {
            this.$set(this.error, key, `${option.name}${isEndWithConsonant(option.name) ? '은' : '는'} 필수입니다.`)
          }
        }
      }
      for (const [field, message] of Object.entries(this.error)) {
        if (message) {
          if (message === '확인 버튼을 눌러 확인하세요.') {
            this.model[field] = ''
          } else {
            return false
          }
        }
      }
      return true
    },
    remove () {
      const confirmed = confirm('정말로 삭제하시겠습니까?')
      if (confirmed) {
        EventBus.$emit('onLoading', true)
        this.removeApi(this.dataInHand, true).then(() => {
          this.show = false
        }).catch(error => {
          alertError('삭제에 실패했습니다.', error)
        }).finally(() => {
          this.$emit('reload')
          EventBus.$emit('onLoading', false)
        })
      }
    },
    save (save = false) {
      let confirmed = false
      if (this.checkRequired()) {
        confirmed = confirm(save ? '정말로 저장하시겠습니까?' : '정말로 저장하지 않고 끝내시겠습니까?')
      }
      console.log("this.edit : ", this.edit)
      if (confirmed) {
        if (save) {
          this.editMode = false
        } else {
          this.loadData(this.edit)
        }
      }
    },
    edit () {
      this.editMode = !this.editMode
    },
    setModel () {
      this.model = {}
      const templateName = this.dataInHand.templateName
      if (templateName === 'adminPvAlarm' ||
        templateName === 'adminBaPvAlarm' ||
        templateName === 'adminBaPlanUpgrade' ||
        templateName === 'adminBaPvEndAlarm'
        /* ||
        templateName === 'adminCodeError' ||
        templateName === 'adminCodeNotInstall' */
      ) {
      //   this.dataOption.field.userText.editable = true
      // } else {
      //   this.dataOption.field.userText.editable = false
      }

      // if (templateName === 'adminBaCodeError') {
      //   this.dataOption.field.imagePath.editable = true
      // } else {
      //   this.dataOption.field.imagePath.editable = false
      // }

      for (const [field, option] of Object.entries(this.dataOption.field)) {
        if (option.key || option.editable) {
          let key = option.field || field
          const value = this.dataInHand[key]
          if (key === 'currentRemainCredit') {
            key = 'remainPV'
          }
          if (key === 'planNumber') {
            const planData = planStore.state.baPlanData[value]
            this.model.planName = planData.planName
            this.model.chargePv = planData.pvLimit
          } else if (key === 'useStartDate' || key === 'useEndDate') {
            const formatStr = 'yyyy-MM-dd'
            const newDate = format(utcToZonedTime(value, 'Asia/Seoul'), formatStr)
            this.model[key] = newDate
            this.dataInHand[key] = newDate
          } else {
            this.model[key] = value
          }
        }
      }
    },
    input (field, value) {
      if (field === 'templateName') {
        if (this.dataInHand.templateName !== value) {
          getEmailTemplate({ templateName: value })
            .then(response => {
              console.log(response)
              const template = response.result
              this.dataInHand.template = template
              this.setData(field, value, true)
            })
            .catch(e => {})
        }
      } else {
        this.setData(field, value)
      }
    },
    setData (field, value, templateChange=false) {
      this.model[field] = value
      if (field === 'planNumber') {
        const planData = planStore.state.beusPlanData[value]
        this.dataInHand.planName = planData.planName
        this.dataInHand.chargePv = planData.pvLimit
      }
      this.dataInHand[field] = value
      this.setEjsRendering(templateChange)
    },
    setEjsRendering (templateChange=false) {
      this.setModel()
      EventBus.$emit('onLoading', true)
      const templateName = this.dataInHand.templateName
      if (templateName) {
        const emailData = JSON.parse(JSON.stringify(this.dataInHand))

        const templateObj = JSON.parse(JSON.stringify(emailData.template))

        let template = null
        let layout = null
        let templateEjs = null
        let serviceType = null

        if (templateObj.template) {
          template = templateObj.template
          templateEjs = template.ejs
        }
        if (templateObj.layout) {
          layout = templateObj.layout
          const replaceKey = layout.replaceKey
          const layoutEjs = layout.ejs
          const tempEjs = layoutEjs.replace(replaceKey, templateEjs)
          template.ejs = tempEjs

          serviceType = emailData.serviceType || 'ba'

          const ejsTemplateName = emailData.templateName
          const emailText = i18n.messages[serviceType][ejsTemplateName]
          emailText.common = i18n.messages[serviceType].common
          emailData.emailText = emailText
        }

        const lang = 'ko'
        emailData.lang = lang

        const subject = emailData.emailText.subject[lang].replace('{userName}', emailData.userName).replace('{planName}', emailData.planName)
        this.dataInHand.subject = subject
        this.model.subject = subject

        if (emailData.useStartDate) {
          const formatStr = 'yyyy년 MM월 dd일'
          const newDate = format(utcToZonedTime(emailData.useStartDate, 'Asia/Seoul'), formatStr)
          emailData.useStartDate = newDate
        }

        if (emailData.useEndDate) {
          const formatStr = 'yyyy년 MM월 dd일'
          const newDate = format(utcToZonedTime(emailData.useEndDate, 'Asia/Seoul'), formatStr)
          emailData.useEndDate = newDate
        }

        if (emailData.userText) {
          emailData.userText = emailData.userText.replace(/\n/g, '<br>')
          this.model.userText = emailData.userText
        }

        emailData.chargePv = emailData.chargePv / 10000

        if (templateName === 'adminCodeNotInstall') {
          const value = this.model.domain
          let domainName = value
          this.dataOption.field.domain.options.forEach((item, idx) => {
            if (item.value === value) {
              domainName = item.name
            }
          })
          emailData.domain = domainName
        }

        if (templateName === 'adminCodeError') {
          emailData.sid = emailData.domain
          this.model.sid = emailData.sid
          this.model.userNum = emailData.userNum
        }

        if (templateName === 'adminCodeNotInstall') {
          this.model.domain = emailData.domain
        }

        for (const key in emailData) {
          if (typeof emailData[key] === 'number') {
            emailData[key] = emailData[key].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
        }

        if (emailData.emailText.common.greeting[lang]) {
          emailData.emailText.common.greeting[lang] = emailData.emailText.common.greeting[lang].replace('{userName}', emailData.userName)
        }

        emailData.remainPV = emailData.currentRemainCredit
        let html = ''
        if (template.ejs && emailData) {
          if (this.dataInHand.content && !templateChange) {
            html = this.dataInHand.content
          } else {
            html = ejs.render(template.ejs, emailData)
          }
        }

        EventBus.$emit('onLoading', false)
        this.$set(this.dataInHand, 'content', html)
      }
    },
    changeTableSort (field, sort) {
      this.tableSortBy[field] = sort
      const desc = sort.indexOf('-') === 0
      sort = sort.replace('-', '').replace('+', '')
      this.dataInHand[field].sort((a, b) => {
        if (desc) {
          return a[sort] > b[sort] ? -1 : 1
        } else {
          return a[sort] < b[sort] ? -1 : 1
        }
      })
    },
    button (value, data) {
      this.$emit('button', value, data)
    },
    closeModal () {
      let confirmed = true
      if (this.editMode) {
        confirmed = confirm('정말로 저장하지 않고 끝내시겠습니까?')
      } else {
        confirmed = confirm('입력한 수정 내용이 초기화 됩니다. 정말로 끝내시겠습니까?')
      }
      if (confirmed) {
        this.show = false
        this.$emit('close')
      }
    }
  },
  data () {
    return {
      dataInHand: null,
      dataOption: null,
      editMode: false,
      show: false,
      model: {
        serviceType: this.$route.meta.ba ? 'ba' : 'beusable'
      },
      error: {},
      tableSortBy: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .remove-button {
    opacity: 0.5;
  }
}
</style>
